document.addEventListener("DOMContentLoaded", () => {
	const menu = new Mmenu( ".mobile-navigation-menu", {
		extensions: [
			"position-back",
			"position-right",
		],
		navbars: [],
		screenReader: {
			aria: true,
			text: true,
		},
	} )

	const menuToggleButton = document.querySelector( ".mobile-menu-button" )
	if ( menuToggleButton ) {
		menuToggleButton.addEventListener( "click", () => {
			if ( document.querySelector( ".mobile-navigation-menu" ).classList.contains( "mm-menu_opened" ) ) {
				menu.API.close()
			}
			else {
				menu.API.open()
			}
		} )
	}

	// Apply FitVids to YouTube videos
	if (jQuery("body").innerWidth() <= 767) {
		jQuery(".inside-content-beckground").fitVids({ ignore: "nofit" });
	}

	// Main nav drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		jQuery(".hasChildren > .nav-drop-toggle-link").click(function(event) {
			if (!jQuery(this).parents(".hasChildren").hasClass("open")) {
				jQuery(".nav-drop-wrap").removeClass("open");
				event.preventDefault();
				jQuery(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		jQuery(".nav-drop-wrap").hover(function() {
			jQuery(this).addClass("open");
		}, function() {
			jQuery(this).removeClass("open");
		});
	}

	// Accessible menu
	jQuery(".accessible-navigation-menu").accessibleMenu();
} )
